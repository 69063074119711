@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "NarkisBlock";
  src: url("./assets/fonts/NarkissBlock-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "NarkisBlock";
  src: url("./assets/fonts/NarkissBlock-Heavy.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "NarkisBlock";
  src: url("./assets/fonts/NarkissBlock-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Benton";
  font-style: normal;
  font-weight: 300;
  src: url("https://www.volkskrant.nl/sel-static/shared-assets/fonts/BentonSansLight.woff2")
      format("woff2"),
    url("https://www.volkskrant.nl/sel-static/shared-assets/fonts/BentonSansLight.woff")
      format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Benton";
  font-style: normal;
  font-weight: 500;
  src: url("https://www.volkskrant.nl/sel-static/shared-assets/fonts/BentonSansBook.woff2")
      format("woff2"),
    url("https://www.volkskrant.nl/sel-static/shared-assets/fonts/BentonSansBook.woff")
      format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Benton";
  font-style: normal;
  font-weight: 700;
  src: url("https://www.volkskrant.nl/sel-static/shared-assets/fonts/BentonSansBold.woff2")
      format("woff2"),
    url("https://www.volkskrant.nl/sel-static/shared-assets/fonts/BentonSansBold.woff")
      format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Benton";
  font-style: normal;
  font-weight: 900;
  src: url("https://www.volkskrant.nl/sel-static/shared-assets/fonts/BentonSansBlack.woff2")
      format("woff2"),
    url("https://www.volkskrant.nl/sel-static/shared-assets/fonts/BentonSansBlack.woff")
      format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Benton";
  src: url("https://www.volkskrant.nl/sel-static/shared-assets/fonts/BentonSansItalic.woff")
    format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Benton";
  src: url("https://www.volkskrant.nl/sel-static/shared-assets/fonts/BentonSansBoldItalic.woff")
    format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "CapitoliumHead";
  font-style: normal;
  font-weight: 300;
  src: url("https://www.volkskrant.nl/sel-static/shared-assets/fonts/CapitoliumHeadLight.woff2")
      format("woff2"),
    url("https://www.volkskrant.nl/sel-static/shared-assets/fonts/CapitoliumHeadLight.woff")
      format("woff");
  font-display: swap;
}
@font-face {
  font-family: "CapitoliumHead";
  font-style: normal;
  font-weight: 500;
  src: url("https://www.volkskrant.nl/sel-static/shared-assets/fonts/CapitoliumHeadReg.woff2")
      format("woff2"),
    url("https://www.volkskrant.nl/sel-static/shared-assets/fonts/CapitoliumHeadReg.woff")
      format("woff");
  font-display: swap;
}
@font-face {
  font-family: "CapitoliumHead";
  font-style: normal;
  font-weight: 700;
  src: url("https://www.volkskrant.nl/sel-static/shared-assets/fonts/CapitoliumHeadBold.woff2")
      format("woff2"),
    url("https://www.volkskrant.nl/sel-static/shared-assets/fonts/CapitoliumHeadBold.woff")
      format("woff");
  font-display: swap;
}
@font-face {
  font-family: "CapitoliumNews";
  font-style: normal;
  font-weight: 500;
  src: url("https://www.volkskrant.nl/sel-static/shared-assets/fonts/CapitoliumNewsReg.woff2")
      format("woff2"),
    url("https://www.volkskrant.nl/sel-static/shared-assets/fonts/CapitoliumNewsReg.woff")
      format("woff");
  font-display: swap;
}
@font-face {
  font-family: "CapitoliumNews";
  font-style: italic;
  font-weight: 500;
  src: url("https://www.volkskrant.nl/sel-static/shared-assets/fonts/CapitoliumNewsItalic.woff2")
      format("woff2"),
    url("https://www.volkskrant.nl/sel-static/shared-assets/fonts/CapitoliumNewsItalic.woff")
      format("woff");
  font-display: swap;
}

button:focus {
  outline: 0;
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-center {
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
}

.circle-background {
  stroke: white;
}

.circle-text {
  font-size: 17px;
  font-family: "NarkisBlock";
  font-weight: 900;
}

.circle-text-progress {
  font-size: 9px;
  font-family: "NarkisBlock";
  font-weight: 900;
}

.cicle-text-mini {
  font-size: 11px;
  font-family: "NarkisBlock";
  font-weight: 900;
}

.cicle-text-progress-mini {
  font-size: 7px;
  font-family: "NarkisBlock";
  font-weight: 900;
}

.rtl-grid {
  direction: rtl;
}

.ltr-grid {
  direction: ltr;
}
